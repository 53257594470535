.page-header-navigation {
    align-items: strech;
    background: white;
    box-shadow: 0 7px 10px -2px rgba(0, 0, 0, .15), 0 3px 5px -1px rgba(0, 0, 0, .06);
    display: flex;
    flex-direction: column;
    gap: 5%;
    justify-content: space-between;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 100;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
}

.page-header-navigation .names {
    font-family: "Lobster";
    font-size: 1.5em;
    line-height: normal;
    padding: 5px 0;
    text-align: center;
    text-decoration: none;
    color: var(--bordeaux);
}

.page-header-navigation a {
    transition: .2s ease-in-out;
    transition-property: color;
    cursor: pointer;
    text-decoration: none;
}

.page-header-navigation a:hover {
    color: var(--bordeaux);
}

.navigation-wrapper {
    align-items: stretch;
    display: flex;
    justify-content: flex-end;
    min-height: 3rem;
    position: relative;
    width: 100%;
}

.headline-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.navigation-container {
    align-items: stretch;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    width: 819.328px;
    touch-action: pan-y;
    user-select: none;
    margin-right: 20px;
}

.navigation-wrapper .navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    list-style-type: none;
    position: absolute;
    transition: .5s ease-in-out;
}

.navigation-wrapper .navigation-item:first-child {
    padding-left: 0;
}

.navigation-wrapper .navigation-item {
    border-top: 3px solid rgba(0, 0, 0, 0);
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    display: inline-block;
    font-size: .9em;
}

.navigation-wrapper .navigation-link.active {
    border-bottom-color: var(--text-primary-color);
    color: var(--text-primary-color);
}

.navigation-wrapper .navigation-link {
    align-items: center;
    color: inherit;
    display: inline-flex;
    height: 100%;
    padding: 0 5px;
    text-decoration: none;
    text-transform: var(--navigation-text-transform);
    white-space: nowrap;
}

.navigation-wrapper .navigation-item.active .navigation-link {
    color: var(--bordeaux);
    font-weight: 600;
}

.navigation-wrapper .navigation-item.active {
    border-bottom: 3px solid var(--bordeaux);
}

.scroll-left,
.scroll-right {
    background-color: white;
    border: none;
    margin-right: 5px;
    margin-left: 5px;
    color: #ad4848;
    font-weight: 600;
}

.scroll-left:hover,
.scroll-right:hover {
    cursor: pointer;
    color: var(--bordeaux);
}

.scroll-left.gray,
.scroll-right.gray {
    color: gray;
    cursor: default;
}

@media (min-width: 550px) {
    .page-header-navigation .names {
        white-space: nowrap;
        margin-left: 20px;
    }
}

@media (min-width: 1080px) {
    .page-header-navigation {
        flex-direction: row;
    }


}

@media (max-width: 1079px) {
    .navigation-wrapper {
        align-items: stretch;
        display: flex;
        justify-content: space-between;
        min-height: 3rem;
        position: relative;
        width: 100%;
    }

    .navigation-container {
        overflow: auto;
        scrollbar-width: none;
        display: flex;
        align-items: flex-start;
    }
}


@keyframes smoothScroll {
    0% {
        transform: translateY(-142px);
    }

    100% {
        transform: translateY(0px);
    }
}