.accomondation-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    overflow-x: hidden;

    .information {
        font-family: "PT Sans", sans-serif;
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.accomondation-list {
    list-style-type: none;
    padding: 0;
    margin-left: 25px;
    margin-right: 25px;
}

.price-list {
    list-style-type: disc;
}

p.accommodation-shuttle {
    padding: 20px;
    background-color: var(--light-green);
    border-radius: 10px;
    width: fit-content;
    margin: auto auto 30px;
}

@media(max-width: 767px) {
    p.accommodation-shuttle {
        margin: auto 25px 30px 25px;
    }
}



