.countdown-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    overflow-x: hidden;
}

.countdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    white-space: nowrap;
}

.counter {
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--light-green); /*rgba(251, 196, 171, 0.6);*/ 
    padding: 15px;
    border-radius: 10px;
}

.counter-number {
    font-size: 2.5rem;
    display: block;
    margin: 10px;
    text-align: center;
}

.counter-unit {
    font-size: 2em;
    font-family: "Lobster";
    text-align: center;
    display: block;
}

@media (max-width: 750px) {
    .counter-number {
        font-size: 2rem;
    }

    .counter-unit {
        font-size: 1rem;
    }

    .counter {
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: var(--light-green); /*rgba(251, 196, 171, 0.6);*/ 
        padding: 5px;
        border-radius: 10px;
    }
}