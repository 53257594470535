.bridal-couple-container {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 auto;
    text-align: center;
    width: 75%;
    overflow-x: hidden;
}

.horizontal-separator {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    text-shadow: none;
    max-width: 50%;
    color: rgba(0, 0, 0, .5);
    margin: 2rem auto;
}

.horizontal-separator::before,
.horizontal-separator::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 70%);
}

.horizontal-separator::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.5) 70%);
}

.bridal-couple-img {
    width: 90px;
}

p.bridal-couple-text {
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
}


@media (min-width: 1070px) {
    .couple {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 40px auto;
        max-width: 1400px;
        gap: 0px 60px;
    }

    .couple div {
        flex: 1;
    }


    .bridal-couple-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .bridal-couple-wrapper>div {
        flex: 1;
        text-align: center;
    }
}


@media (min-width: 750px) and (max-width: 1070px) {
    .couple {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 40px auto;
        max-width: 1000px;
        gap: 0px 10px;
    }
}

@media (max-width: 1070px) {
    .bridal-couple-img {
        width: 70px;
    }

    p.bridal-couple-text {
        font-family: "Great Vibes", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 22px;
    }
}