.abc-container {
    max-width: 1300px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin: auto;
    width: 100%;
    overflow-x: hidden;
}

.abc-content {
    list-style-type: none;
    margin: 0;
    padding: 0;
    columns: 2;
}

@media (max-width: 750px) {
    .abc-content {
        columns: 1;
    }
}

.abc-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    padding: 0.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid-column;
    page-break-inside: avoid;
    margin-bottom: 10px;
}

div.abc-letter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    color: var(--bordeaux);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "Lobster", sans-serif;
    font-weight: bold;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    text-align: center;
    font-size: 2rem;
    width: 2rem;
    margin-right: 1.5rem;
}

.abc-text {
    width: calc(100% - 50px);
}

@media (max-width: 750px) {
    div.abc-letter {
        font-size: 1.5rem;
    }
}

