.fix-image-container {
    width: 100%;
    min-height: 250px;
    background-image: url('../../content/images/Zitat_Bild.jpeg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: #fff;
    text-align: center;
    padding: 0;
    position: relative;
    max-width: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    overflow-x: hidden;
}

.quote-large {
    font-size: 2rem;
    text-shadow: 1px 1px 1px #000;
    margin: 10px 40px;
}

.author {
    text-shadow: 1px 1px 1px #000;
}

@media (max-width: 750px) {
    .quote-large {
        font-size: 1.5rem;
        margin: 10px 40px;
    }

    .fix-image-container {
        width: 100%;
        min-height: 250px;
        background-image: url('../../content/images/Zitat_Bild.jpeg');
        background-size: cover;
        background-position: center center;
        background-attachment: scroll;
        color: #fff;
        text-align: center;
        padding: 0;
        position: relative;
        max-width: 100%;
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}