.popup {
    display: grid;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 600px;
    padding: 20px;
    z-index: 1000;
    grid-template-areas:
            "image close"
            "image main"
            "image main";
    grid-template-columns: 1fr 3fr;
    column-gap: 20px;
}
.popup.show {
    display: block; /* Zeige das Popup */
}

.popup-body {
    grid-area: main;
    margin: 15px 0;
}

.popup-body h3 {
    text-align: center;
    margin-top: 0;
}

.popup-button {
    justify-self: end;
    max-width: fit-content;
    grid-area: close;
    background: var(--light-green);
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-family: "PT Sans", sans-serif;
    font-size: 1rem;
}

.popup-button:hover {
 background: #d5e3d0;
}

.popup-close {
    background: gray;
    color: white;
    padding: 5px 10px;
}

.popup-close:hover {
    background: #acabab;
}

.popup-image {
    grid-area: image;
    max-width: 200px;
    align-self: center;
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Halbtransparentes Schwarz */
    z-index: 999; /* Unter dem Popup, aber über anderen Inhalten */
}

@media(max-width: 576px) {
    .popup {
        grid-template-areas:
            "close"
            "image"
            "main";
        column-gap: 0;
    }

    .popup-body {
        text-align: center;
    }

    .popup-image {
        justify-self: center;
        row-gap: 20px;
        width: 150px;
    }

    .popup-close {
        font-size: 0.75rem;
        margin-bottom: 10px;
    }
}
