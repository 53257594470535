.location-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    overflow-x: hidden;
}

.location-list {
    list-style-type: none;
    padding: 0;
}

.location {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 900px;
    text-align: left;
    gap: 1rem 2rem;
    margin: 0px auto 2rem;
}

.location-map {
    font-family: "Font Awesome 5 Free";
}

.location-map-icon {
    color: #fff;
    display: flex;
    font-size: 3rem;
    height: 25%;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: 68px;
    transition: opacity 1.5s ease-in-out;
    width: 100%;
    z-index: 2;
}

.wedding-location-list {
    .location-map-image {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 400px;
        height: 200px;
    }

    .location-map-image img {
        width: 400px;
        border-radius: 10px;
        height: 200px;
    }

}

.location-map-image {
    height: 160px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 260px;
}

.location-map-image img {
    width: 260px;
    border-radius: 10px;
    height: 160px;
}

.location-map {
    position: relative;
    display: inline-block;
}

.location-map::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bordeaux);
    opacity: 0;
    transition: opacity 1.5s ease;
    z-index: 1;
    border-radius: 10px;
}

.location-map:hover::before {
    opacity: 0.8;
    border-radius: 10px;
}

.location-map:hover .location-map-icon {
    opacity: 1;
    border-radius: 10px;
}

.location-details {
    text-align: left;
}

.location-details h3 {
    margin-top: 0;
}

.location-infos {
    color: var(--link-color);
}

.location-infos:hover {
    color: var(--link-color);
}

.location-infos:visited {
    color: var(--link-color)
}

@media (max-width: 750px) {
    .location {
        flex-direction: column;
        text-align: center;
    }

    .location-details .accommodation-name {
        text-align: center;
    }

    .location-details {
        text-align: left;
    }

    .location-map-image {
        height: 160px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 240px;
    }

    .location-map-image img {
        height: 160px;
        border-radius: 10px;
        width: 240px;
    }
}
