#feedback {
    scroll-margin-top: 48px;
}

.feedback-form {
    max-width: 45rem;
    margin: 30px auto 1rem;
    overflow: hidden;
    transition: height 0.5s linear;
    height: fit-content;
}

.radio-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-group {
    display: flex;
}

.radio {
    display: inline-block;

    .custom-radio-btn {
        --size: 25px;
        min-width: var(--size);
        height: var(--size);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        /* changes from the video   */

        margin: 0 10px;
        position: relative;
        padding-left: var(--size);
    }

    input {
        display: none;
    }


    .custom-radio-btn .radio-label {
        margin-left: 5px;
        font-size: 1.1rem;
        font-family: "PT Sans", sans-serif;
    }



    .custom-radio-btn[aria-label]::after {
        content: attr(aria-label);
        display: inline-block;
        margin-left: 5px;
    }

    .custom-radio-btn .radio-checkmark {
        --gap: 3px;
        --border: 2px;
        --check-color: var(--light-orange);
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        display: inline-block;
        transition: opacity 0.3s ease;

        border: var(--border) solid var(--light-orange);
        padding: var(--gap);
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
    }

    .custom-radio-btn input:checked~.radio-checkmark {
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: radial-gradient(circle at center,
                var(--check-color) calc((var(--size) - (var(--border) * 2.5) - (var(--gap) * 2.5)) / 2.5),
                rgba(255, 255, 255, 0) 0);
    }
}

.form-group {
    margin: 1rem auto;
    display: flex;
}

.form-group-two {
    margin: 0.75rem 0;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .form-control:first-of-type {
        margin-right: 5px;
    }

    .form-control:last-of-type {
        margin-left: 5px;
    }

}

.form-control {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    color: inherit;
    font-size: 1rem;
    font-family: "PT Sans", sans-serif;
    outline: none;
    padding: 0.75rem;
    width: fit-content;
    flex-grow: 1;

    &.error {
        border: 2px solid #f62653;
        color: #f62653;
    }

    &.error::placeholder {
        color: #f62653;
    }
}

textarea.form-control {
    height: 65px;
}

button.form-control {
    background-color: var(--light-green);
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.form-control:hover {
    background-color: var(--dark-green);
    cursor: pointer;
}

.success-message {
    display: flex;
    justify-content: center;
    /* Horizontal zentrieren */
    align-items: center;
    background-color: #d7e8ca;
    text-align: center;
    border: 1px solid #9aaf8f;
    border-radius: 4px;
    max-width: 45rem;
    height: 0px;
    transition: height 1s ease;
    visibility: hidden;
}

.success-message.active {
    height: '20px';
    visibility: visible;
    margin: 20px auto;
    padding: 20px;
}

.error-message {
    color: #f62653;
    margin: 10px auto;
    padding: 10px;
    max-width: 45rem;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid rgb(168, 166, 166);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    margin-left: 1rem;
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {

    #feedback {
        scroll-margin-top: 85px;
    }

    .feedback-form {
        margin: 30px 20px;
    }

    .radio-container {
        flex-direction: column;
    }

    .radio-container .radio:first-child {
        margin-bottom: 0.75rem;
    }

    .form-group-two input.form-control {
        flex: 100%;
    }

    .form-group-two input.form-control:first-of-type {
        margin-right: 0px;
        margin-bottom: 1rem;
    }

    .form-group-two input.form-control:last-of-type {
        margin-left: 0px;
    }

    .form-group textarea.form-control {
        height: 110px;
    }

    .success-message.active {
        margin: 20px 20px;
    }
}
