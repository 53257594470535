.wedding-location-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    overflow-x: hidden;
}

.wedding-location-list {
    list-style-type: none;
    padding: 0;
    margin-left: 25px;
    margin-right: 25px;
}
