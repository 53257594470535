.image-slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* Horizontal zentrierte Ausrichtung */
    align-items: center;
    overflow-x: hidden;

    h1,
    h2 {
        text-shadow: 1px 1px 1px #000;
    }
}

.image-content {
    position: relative;
    z-index: 2;
    text-align: center;
    transform: translateY(0px);
    opacity: 1;
    transition: opacity 1.5s ease, transform 1.5s ease; 
}

.image-content.hidden {
    opacity: 0;
    transform: translateY(100px);
}

.slider-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 0.9;
    transition: opacity 0.5s ease;
}

.header-icons {
    margin: 0 10px;
    white-space: nowrap;
    width: fit-content;
}

.heart-icon {
    color: white
}

.large-heart {
    font-size: 1.2rem;
}

.horizontal-separator-white {
    align-items: center;
    display: flex;
    margin: 10px 0;
    text-shadow: none;
}

.horizontal-separator-white::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, white 70%);
}

.horizontal-separator-white::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, white 70%);
}