.witnesses-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    overflow-x: hidden;
}

.witnesses {
    list-style-type: none;
    margin: 0 20px;
    padding: 0;
}

.witnesses li {
    flex: 1;
}

@media (min-width: 1070px) {
    .witnesses {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 40px auto;
        max-width: 1400px;
        gap: 0px 5px;
    }
}


@media (min-width: 750px) and (max-width: 1070px) {
    .witnesses {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 40px auto;
        max-width: 1000px;
        gap: 0px 10px;
    }
}