:root {
    --light-orange: #ac1e3d;
    --light-green: #bccfb2;
    --dark-green: #8eab7e;
    --bordeaux: #8b2323;
    --link-color:   #f4978e;
    --header-section-foreground-color-rgb: 255, 255, 255;
    overflow-x: hidden;

    body {
        margin: 0;
        overflow-x: hidden;
    }

    p,
    div {
        font-family: "PT Sans", sans-serif;
    }
}

.section-header {
    &.orange {
        scroll-margin-top: 48px;
        background-color: var(--light-orange);
        padding: 30px 0;
    }

    &.green {
        background-color: var(--light-green);
    }

    color: white;
    padding: 30px 0;

    h2 {
        font-size: 2rem;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0;
    }

    h2:after {
        background-color: rgba(var(--header-section-foreground-color-rgb), 0.3);
        content: "";
        display: block;
        height: 1px;
        margin: 15px auto 0;
        width: 120px;
    }

    p {
        font-family: "PT Sans", sans-serif;
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
    }
}

h1,
h2 {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 5em;
    text-align: center;
    position: relative;
    z-index: 2;
    color: white;
}

h2 {
    font-size: 2.5em;
}

@media (min-width: 750px) and (max-width: 1000px) {
    h1 {
        font-size: 4em;
    }

    h2 {
        font-size: 2em;
    }

}

@media (max-width: 750px) {
    h1 {
        font-size: 2.5em;
    }

    h2 {
        font-size: 1.5em;
    }

    .section-header {
        &.orange {
            scroll-margin-top: 85px;
            background-color: var(--light-orange);
            padding: 30px 0;
        }

        h1 {
            font-size: 2.5em;
        }

        h2 {
            font-size: 1.5em;
        }

        p {
            font-size: 1rem;
        }
    }

}