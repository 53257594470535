.intro-container {
    scroll-margin-top: 70px;
}

.intro-text {
    color: var(--bordeaux);
}

.intro-text-text {
    text-align: center;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.intro-horizontal-separator {
    align-items: center;
    display: flex;
    margin: 10px 0;
    text-shadow: none;
}

.intro-text-icon {
    margin: 0 10px;
    white-space: nowrap;
    width: fit-content;
    color: rgba(0,0,0,0.2);
    font-size: 1.5rem;
}

.intro-horizontal-separator::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, rgba(0,0,0,0.2) 70%);
}

.intro-horizontal-separator::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, rgba(0,0,0,0.2) 70%);
}