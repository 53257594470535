.timeline-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    overflow-x: hidden;
}

.timeline-activities {
    padding: 0;
}

.timeline-activity {
    margin-bottom: 2rem;
    text-align: center;
    list-style: none;
}

.activity-icon-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--light-green);
    border: 5px solid white;
    border-radius: 50%;
    color: var(--foreground-color-on-primary-color-background);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 2.5rem;
    height: 3.5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.5rem;
    position: relative;
    text-align: center;
    width: 3.5rem;
    z-index: 2;
}

.activity-icon {
    width: 50px;
}

.activity-headline {
    font-size: 1.75rem;
    font-family: "Bad Script", cursive;
    font-weight: 200;
    text-transform: var(--headline-text-transform);
    margin-top: 20px;
}

@media (max-width: 750px) {
    .timeline-activity:after {
        background: rgba(0, 0, 0, 0.2);
        content: "";
        display: block;
        height: 1.5rem;
        margin: 1rem auto -1rem;
        width: 1px;
    }

    .activity-headline {
        font-size: 1.25rem;
        font-weight: 200;
    }

    .timeline-activity:last-child::after {
        background: none;
    }

}

@media (min-width: 750px) {
    .timeline-activities {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        padding: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
    

    .timeline-activity {
        display: flex;
        margin: 0 0 30px;
        text-align: left;
        width: calc(50% + 2.5rem);
        gap: 1px 1rem;
    }
    
    .timeline-activities::before {
        background: rgba(0, 0, 0, 0.2);
        content: "";
        height: 100%;
        left: 50%;
        position: absolute;
        width: 1px;
    }
    
    .timeline-activity:nth-child(odd) {
        align-self: flex-start;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        width: calc(50% + 2.5rem);
    }
    
    .timeline-activity:nth-child(even) {
        -ms-flex-item-align: end;
        align-self: flex-end;
        width: calc(50% + 2.5rem);
    }

    .activity-icon-wrapper:nth-child(odd) {
        -webkit-box-direction: reverse;
        text-align: right;
    }
}