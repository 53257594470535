#menu {
    position: relative;
}
.menu-form {
    max-width: 45rem;
    margin: 30px auto 1rem;
    overflow: hidden;
    transition: height 0.5s linear;
    height: fit-content;
}

.form-group {
    margin: 1rem auto;
    display: flex;
    gap: 1rem;
}

#overlayDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Das Overlay-Div soll die gesamte Breite des Formulars einnehmen */
    height: 100%; /* Das Overlay-Div soll die gesamte Höhe des Formulars einnehmen */
    background-color: rgba(255, 255, 255, 0.7); /* Weiße Hintergrundfarbe mit 30% Opazität */
    z-index: 2;
}

@media (max-width: 768px) {
    .menu-form {
        margin: 30px 20px;
    }

    .form-group.menu {
        display: flex;
        flex-direction: column;
    }

    .form-control.menu {
        width: auto;
    }
}