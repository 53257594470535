footer.footer {
    background-color: var(--light-orange);
}

.footer-container {
    max-width: 1170px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    margin: auto;
    overflow-x: hidden;
}

p.footer-text {
    font-family: "Lobster";
    font-size: 1.5em;
    color: white;
}

p.footer-text-small {
    font-family: "Lobster";
    color: white;
}