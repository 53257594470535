.login-box {
    position: fixed;
    top: 40px;
    left: 40px;
    backdrop-filter: blur(3px);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, .5);
    display: inline-block;
    max-width: 35rem;
    min-width: 300px;
    padding: 1rem;
    z-index: 1;
    overflow-x: hidden;

    button.form-control {
        background-color: var(--light-green);
    }

    h2 {
        color: var(--light-green);
        margin-top: 0;
    }

    b {
        color: var(--dark-red);
    }
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    object-fit: cover;
}

@media (max-width: 750px) {
    .login-container {
        display: flex;
        justify-content: center;
    }

    .login-box {
        margin-top: 60px;
        left: auto;
        top: auto;
    }
}