.person {
    margin: 2rem 0;
    text-align: center;
}

.person-image {
    border-radius: 50%;
    display: block;
    height: 215px;
    margin: 0 auto 1rem;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
    width: 215px;
}

.person-name {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: var(--headline-text-transform);
}
.person-title {
    font-size: .9rem;
    font-style: italic;
}

.phone-icon {
    margin-right: 15px;
}

.person-phone {
    color: var(--link-color);
}

.person-phone:hover {
    color:  var(--link-color);
}

.person-phone:visited {
    color: var(--link-color);
}

.person-quote {
    /* background-color: rgba(251, 196, 171, 0.6); */
    padding: 15px 15px 0 15px;
    margin-bottom: 5px;
    border-radius: 10px;
    white-space: pre-wrap;
}

.person-quote-name {
    margin-top: 0;
}


@media (min-width: 750px) {
    .person {
        max-width: 50%;
        padding: 0 1rem;
    }
}

@media (max-width: 750px) {
    .person-image {
        border-radius: 50%;
        display: block;
        height: 180px;
        margin: 0 auto 1rem;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
        width: 180px;
    }
}